import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import DatePicker from 'vue2-datepicker'
@Component({
  components: {
    DatePicker,
  },
})
export default class MultiSelect extends Vue {
  @Prop() private list!: Array<any>
  @Prop() private label!: string
  @Prop({ default: 150 }) private maxWidth!: number
  @Prop() private icon!: string
  @Prop() private typeInputPicker!: string

  data: Array<any> = []
  added: Array<any> = [
    {
      text: '',
      value: '',
      disabled: false,
    },
  ]

  mounted() {
    this.data = this.list.map((x: any) => ({
      ...x,
      disabled: false,
      quantity: 1,
    }))
  }

  get updateList(): Array<any> {
    console.log(this.added)
    return this.added
  }

  addRow() {
    this.added.push({
      text: '',
      value: '',
      disabled: false,
      quantity: 0,
    })
  }

  confirmation() {
    if (
      this.added[this.added.length - 1].quantity > 0 &&
      this.added[this.added.length - 1].value != ''
    ) {
      this.added = this.added.map((x: any) => ({
        value: x.value,
        quantity: x.quantity,
        disabled: true,
      }))
      this.$emit(
        'updateList',
        this.added.map((x: any) => ({
          value: x.value,
          quantity: x.quantity,
        })),
      )
    } else {
      this.$buefy.toast.open({
        message: `Debe llenar todos los campos, no puede agregar números negativos`,
        type: 'is-warning',
      })
    }
  }

  remove(index: any) {
    if (this.added.length == 1) {
      this.addRow()
    }
    const objIndex = this.data.findIndex(
      (obj: any) => obj.value == this.added[index].value,
    )
    this.added.splice(index, 1)
  }
}
